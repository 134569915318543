import React from "react";
import SlideComponent from "../../components/SildeComponent/SlideComponent";
import "./Home.css";
import Galeria from "../../components/GaleriaComponent/GaleriaComponent";
import TextBlockComponent from "../../components/TextBlockComponent/TextBlockComponent";
import BalancingComponent from "../../components/BalancingComponent/BalancingComponent";
import SplitComponent from "../../components/SplitComponent/SplitComponent";
import MidiaComponent from "../../components/MidiaComponent/MidiaComponent";
import TestemonyComponent from "../../components/TestemonyComponent/TestemonyComponent";
import TopNavbar from "../../components/Navigation/TopNavbar";
import SimpleDivComponent from "../../components/SimpleDivComponent/SimpleDivComponent";
import TitlePageComponent from "../../components/Titles/TitlePageComponent";
import PolicyComponent from "../../components/Policycomponent/PolicyComponent";

const Home = () => {
  return (
    <>
      <TopNavbar showAddresses />
      <SlideComponent />
      <TextBlockComponent />
      <BalancingComponent />
      <SplitComponent />
      <MidiaComponent />
      <div style={{ backgroundColor: "#fcf9f4" }}>
        <TitlePageComponent title={"Testemunhos"} />
        <TestemonyComponent />
        <Galeria />
      </div>
      <SimpleDivComponent text="Nosso maior objetivo é que cada criança se desenvolva dentro de seus potenciais e que suas habilidades a permitam serem independentes e bem-sucedidas a longo prazo." />
      <PolicyComponent />
    </>
  );
};

export default Home;
