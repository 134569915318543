import React, { useState, useEffect } from "react";
import axios from 'axios';
import "./BigFooter.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import {
  faCaretRight,
  faEnvelope,
  faMapMarkerAlt,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Tooltip, Box } from "@mui/material";
import Logo from "../../assets/logo.webp";
import { apiUrl, authorizationBasicToken } from "../../config.js";

// Icons
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const BigFooterComponent = () => {

  const headers = {
    'Authorization': authorizationBasicToken,
    'Content-Type': 'application/json'
  };

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  useEffect(() => {
    getDadosContato();
    getRedesSociais();
  }, []);

  const getDadosContato = async () => {
    axios.get(`${apiUrl}/dados-contato`, {
      headers: headers,
    })
    .then((response) => setData1(response.data && response.data.data.length ? response.data.data[0] : {}))
    .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  const getRedesSociais = async () => {
    axios.get(`${apiUrl}/redes-sociais`, {
      headers: headers,
    })
    .then((response) => setData2(response.data && response.data.data.length ? response.data.data[0] : {}))
    .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  
  return (
    <footer className="big-footer">
      <div className="footer-content">
        <div className="footer-section">
          <img src={Logo} alt="Logo" className="footer-logo" />
          <Box sx={{ flexGrow: 1, m: 0 }}>
            <Tooltip title="Encontre-nos no Facebook">
              <Button
                href={data2?.facebook}
                target="_blank"
                variant="contained"
                size="small"
                sx={{
                  p: 1,
                  mx: 1,
                  borderRadius: 50,
                  width: "auto",
                  minWidth: "auto",
                }}
              >
                <FacebookIcon fontSize="1em" />
              </Button>
            </Tooltip>
            <Tooltip title="Assista-nos no Youtube">
              <Button
                href={data2?.youtube}
                target="_blank"
                variant="contained"
                color="error"
                size="small"
                sx={{
                  p: 1,
                  mx: 1,
                  borderRadius: 50,
                  width: "auto",
                  minWidth: "auto",
                }}
              >
                <YouTubeIcon fontSize="1em" />
              </Button>
            </Tooltip>
            <Tooltip title="Siga-nos no TikTok">
              <Button
                href={data2?.tiktok}
                variant="contained"
                size="small"
                sx={{
                  p: 1,
                  mx: 1,
                  borderRadius: 50,
                  width: "auto",
                  minWidth: "auto",
                }}
              >
                <FontAwesomeIcon icon={faTiktok} />
              </Button>
            </Tooltip>
            <Tooltip title="Siga-nos no Instagram">
              <Button
                className="button-instagram"
                href={data2?.instagram}
                target="_blank"
                style={{backgroundColor: "#ff4880"}}
                variant="contained"
                size="small"
                sx={{
                  p: 1,
                  mx: 1,
                  borderRadius: 50,
                  width: "auto",
                  minWidth: "auto",
                }}
              >
                <InstagramIcon fontSize="1em" />
              </Button>
            </Tooltip>
            <Tooltip title="Fale conosco no WhatsApp">
              <Button
                href={`https://api.whatsapp.com/send?phone=${data2?.whatsapp?.replace('-', '')?.replace('(', '')?.replace(')', '')?.replace(' ', '')}&text=Ol%C3%A1!%20Tudo%20bem%3F%20Gostaria%20de%20agendar%20uma%20avalia%C3%A7%C3%A3o.%20`}
                variant="contained"
                size="small"
                color="success"
                target="_blank"
                sx={{
                  p: 1,
                  mx: 1,
                  borderRadius: 50,
                  width: "auto",
                  minWidth: "auto",
                }}
              >
                <WhatsAppIcon fontSize="1em" />
              </Button>
            </Tooltip>
          </Box>
        </div>
        <div className="footer-section">
          <h3 style={{ fontFamily: "Fredoka One", fontSize: "24px" }}>
            Nosso Contato
          </h3>
          <FontAwesomeIcon icon={faMobileScreen} style={{ color: "#ffc000" }} />{" "}
          <a
            href={`tel:${data1?.telefone}`}
            style={{ textDecoration: "none", color: "#fff" }}
          >
            {data1?.telefone}
          </a>
          <p>
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{ color: "#ffc000" }}
          />{" "}
            {data1?.email}
          </p>
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            style={{ color: "#ffc000" }}
          />{" "}
          {data1?.endereco}, {data1?.numero}
          <p>{data1?.bairro}, {data1?.cidade} - {data1?.estado}.</p>
        </div>
        <div className="footer-section">
          <h3 style={{ fontFamily: "Fredoka One", fontSize: "24px" }}>Menu</h3>
          <ul className="footer-menu">
            <li>
            <FontAwesomeIcon icon={faCaretRight} style={{ color: "#ffc000" }} /> {" "}
              <Link to="/" onClick={handleLinkClick}>Página principal</Link>
            </li>
            <li>
            <FontAwesomeIcon icon={faCaretRight} style={{ color: "#ffc000" }} /> {" "}
              <Link to="/centro-integrar" onClick={handleLinkClick}>Centro Integrar</Link>
            </li>
            <li>
            <FontAwesomeIcon icon={faCaretRight} style={{ color: "#ffc000" }} /> {" "}
              <Link to="/acompanhamento" onClick={handleLinkClick}>Acompanhamento</Link>
            </li>
            <li>
            <FontAwesomeIcon icon={faCaretRight} style={{ color: "#ffc000" }} /> {" "}
              <Link to="/intervencao-aba" onClick={handleLinkClick}>Intervenção com ABA</Link>
            </li>
            <li>
            <FontAwesomeIcon icon={faCaretRight} style={{ color: "#ffc000" }} /> {" "}
              <Link to="/metodologia-integrar" onClick={handleLinkClick}>Metodologia Integrar</Link>
            </li>
            <li>
            <FontAwesomeIcon icon={faCaretRight} style={{ color: "#ffc000" }} /> {" "}
              <Link to="/atividades" onClick={handleLinkClick}>Atividades</Link>
            </li>
            <li>
            <FontAwesomeIcon icon={faCaretRight} style={{ color: "#ffc000" }} /> {" "}
              <Link to="/fale-conosco" onClick={handleLinkClick}>Fale Conosco</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default BigFooterComponent;
