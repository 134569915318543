import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TitleSectionComponent from "../components/TitleSectionComponent/TitleSectionComponent";
import BackgroundImage from "../assets/lego-image.jpg";
import Image from "../assets/faixada-centro.jpg";
import TopNavbar from "../components/Navigation/TopNavbar";
import './About.css';
import Galeria from "../components/GaleriaComponent/GaleriaComponent";
import { apiUrl, authorizationBasicToken } from "../config.js";

const About = () => {
  const headers = {
    'Authorization': authorizationBasicToken,
    'Content-Type': 'application/json'
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    getCentroIntegrar();
  }, []);

  const getCentroIntegrar = async () => {
    axios.get(`${apiUrl}/centro-integrar`, {
      headers: headers,
    })
    .then((response) => setData(response.data && response.data?.data.length ? response.data.data[0] : {}))
    .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  return (
    <div>
      <TopNavbar showAddresses />
      <TitleSectionComponent
        title="Centro Integrar"
        backgroundImage={BackgroundImage}
      />
      <div className="about-container">
        <div className="about-text">
          <p style={{color: '#777777'}}>
            {data?.descricao}
          </p>
        </div>
        <div className="about-image">
          <img src={Image} alt="Imagem" />
        </div>

      </div>
      <Galeria />
    </div>
  );
};

export default About;
