import React from 'react';
import './Footer.css';
import LogoImage from '../../assets/LOGO PD TECH - BRANCO.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>
          &copy; 2023 Centro Integrar, Todos os direitos reservados. {" "} 
          <a href="/politica-privacidade" style={{ color: '#ffc000' }}>Política de privacidade.</a>
        </p>
        <div className="logo">
        <a href="https://www.pdtech.com.br" target='_blank' rel="noreferrer">
          <img src={LogoImage} alt="Logo" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
