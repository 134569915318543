import React, { useState, useEffect } from "react";
import axios from 'axios';
import "./TextBlockComponent.css";
import TitlePageComponent from "../Titles/TitlePageComponent";
import { apiUrl, authorizationBasicToken } from "../../config.js";

const TextBlockComponent = () => {
  const h3Style = {
    fontFamily: "Fredoka One",
    fontSize: "26px",
  };

  const headers = {
    'Authorization': authorizationBasicToken,
    'Content-Type': 'application/json'
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    getPorqueEscolher();
  }, []);

  const getPorqueEscolher = async () => {
    axios.get(`${apiUrl}/home-porque-escolher`, {
      headers: headers,
    })
    .then((response) => setData(
      response.data && response.data?.data.length ? response.data.data.sort((a, b) => (a.ordem > b.ordem ? 1 : -1)) : [])
    )
    .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  return (
    <div className="text-image-component">
      <TitlePageComponent
        beforeTitle={"Porque escolher o"}
        title={"Centro Integrar"}
      />

      <div className="color-blocks">
        {data?.map((item, index) => (
          <div className="color-block" style={{ backgroundColor: item.background_color }} key={item.id}>
            <h3 style={h3Style}>
              {item.titulo}
            </h3>
            <p>
              {item.descricao}{" "}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TextBlockComponent;
