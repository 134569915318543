import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TitleSectionComponent from "../components/TitleSectionComponent/TitleSectionComponent";
import BackgroundImage from "../assets/lego-image.jpg";
import TopNavbar from "../components/Navigation/TopNavbar";
import { apiUrl, authorizationBasicToken } from "../config.js";

const IntervencaoABA = () => {
    const headers = {
        'Authorization': authorizationBasicToken,
        'Content-Type': 'application/json'
    };

    const [data, setData] = useState([]);

    useEffect(() => {
        getIntervencaoABA();
    }, []);

    const getIntervencaoABA = async () => {
        axios.get(`${apiUrl}/autismo/intervencao`, {
            headers: headers,
        })
        .then((response) => setData(
            response.data && response.data?.data.length ? response.data.data.sort((a, b) => (a.ordem > b.ordem ? 1 : -1)) : [])
        )
        .catch((error) => console.error('Erro ao buscar dados:', error));
    };

    const containerStyle = {
        display: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        paddingLeft: '40px',
        paddingRight: '40px',
        textAlign: 'justify',
    };

    const pStyle = {
        fontFamily: 'Prompt',
        color: '#777777',
        fontSize: '15px',
        fontWeight: '400px',
        lineHeight: '1.67',
    };

    return (
        <>
            <TopNavbar showAddresses />
            <TitleSectionComponent
                title="Intervenção com ABA"
                backgroundImage={BackgroundImage} />
            <div style={containerStyle}>
                {data?.map((item) => (
                    <p key={item.id} style={pStyle}>
                        {item?.descricao}
                    </p>
                ))}
            </div>
        </>
    )
}

export default IntervencaoABA;
