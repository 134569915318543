import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TitleSectionComponent from "../components/TitleSectionComponent/TitleSectionComponent";
import BackgroundImage from '../assets/lego-image.jpg';
import TopNavbar from "../components/Navigation/TopNavbar";
import { apiUrl, authorizationBasicToken } from "../config.js";

const Acompanhamento = () => {
    const headers = {
        'Authorization': authorizationBasicToken,
        'Content-Type': 'application/json'
    };

    const [data, setData] = useState([]);

    useEffect(() => {
        getAcompanhamento();
    }, []);

    const getAcompanhamento = async () => {
        axios.get(`${apiUrl}/acompanhamento`, {
            headers: headers,
        })
        .then((response) => setData(
            response.data && response.data?.data.length ? response.data.data.sort((a, b) => (a.ordem > b.ordem ? 1 : -1)) : [])
        )
        .catch((error) => console.error('Erro ao buscar dados:', error));
    };

    const containerStyle = {
        display: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center', // Centraliza horizontalmente
        justifyContent: 'center', // Centraliza verticalmente
        padding: '20px',
        paddingLeft: '40px',
        paddingRight: '40px',
        textAlign: 'left',
    };
    const h1Style = {
        fontFamily: 'Fredoka One',
        color: '#271344',
        fontSize: '40px'
    };

    const pStyle = {
        fontFamily: 'Prompt',
        color: '#777777',
        fontSize: '15px',
        fontWeight: '400px',
    };

    return (
        <>
            <TopNavbar showAddresses />
            <TitleSectionComponent
                title="Acompanhamento"
                backgroundImage={BackgroundImage} />
            <div style={containerStyle}>
                {data?.map((item) => (
                    <>
                    <h1 style={h1Style}>
                        {item.titulo}
                    </h1>
                    <p style={pStyle}>
                        {item.descricao}
                    </p>
                    </>
                ))}
            </div>
        </>
    )
}

export default Acompanhamento;
