import React, { useState, useEffect } from "react";
import axios from 'axios';
import './BalancingComponent.css';
import Image from  '../../assets/image-left-home.png';
import { apiUrl, authorizationBasicToken } from "../../config.js";

const BalancingComponent = () => {
  
  const headers = {
    'Authorization': authorizationBasicToken,
    'Content-Type': 'application/json'
  };

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  useEffect(() => {
    getNossosServicos();
    getRedesSociais();
  }, []);

  const getNossosServicos = async () => {
    axios.get(`${apiUrl}/home-nossos-servicos`, {
      headers: headers,
    })
    .then((response) => setData(response.data && response.data.data.length ? response.data.data[0] : {}))
    .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  const getRedesSociais = async () => {
    axios.get(`${apiUrl}/redes-sociais`, {
      headers: headers,
    })
    .then((response) => setData2(response.data && response.data.data.length ? response.data.data[0] : {}))
    .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  const image = `${apiUrl}/${data.imagem}`;

  return (
    <div className="balancing-component">
      <div className="image-container">
        <img src={image} />
      </div>
      <div className="text-blocks">
        <div className="text-block">
          <h3 style={{fontFamily: 'Fredoka One', color: "#271344"}}>{data?.titulo1}</h3>
          <p style={{ color: '#777777' }}>{data?.descricao1}</p>
        </div>
        <div className="text-block">
          <h3 style={{ fontFamily: 'Fredoka One', color: "#271344" }}>{data?.titulo2}</h3>
          <p style={{ color: '#777777' }}>{data?.descricao2}</p>
        </div>
        <div className="text-block">
          <h3 style={{ fontFamily: 'Fredoka One', color: "#271344" }}>{data?.titulo3}</h3>
          <p style={{ color: '#777777' }}>{data?.descricao3}</p>
          <a href={`https://api.whatsapp.com/send?phone=${data2?.whatsapp?.replace('-', '')?.replace('(', '')?.replace(')', '')?.replace(' ', '')}&text=Ol%C3%A1!%20Tudo%20bem%3F%20Gostaria%20de%20agendar%20uma%20avalia%C3%A7%C3%A3o.%20`} target="_blank" rel="noopener noreferrer">
          <button style={{fontFamily:'Fredoka One', borderRadius: '5px', padding: '15px', backgroundColor: '#ff4880'}}>Fale conosco</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BalancingComponent;
