import React, { useState, useEffect } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { apiUrl, authorizationBasicToken } from "../../config.js";

// Components
import {
  AppBar,
  Button,
  Toolbar,
  Tooltip,
  styled,
  Box,
  Typography,
  Divider,
  Container,
} from "@mui/material";

// Icons
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const AppBarCustom = styled(AppBar)(({ }) => ({
  backgroundColor: "#271344",
}));

const AddressHeaderComponent = (props) => {

  const headers = {
    'Authorization': authorizationBasicToken,
    'Content-Type': 'application/json'
  };
  
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  useEffect(() => {
    getDadosContato();
    getRedesSociais();
  }, []);

  const getDadosContato = async () => {    
    axios.get(`${apiUrl}/dados-contato`, {
      headers: headers,
    })
    .then((response) => setData1(response.data && response.data.data.length ? response.data.data[0] : {}))
    .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  const getRedesSociais = async () => {
    axios.get(`${apiUrl}/redes-sociais`, {
      headers: headers,
    })
    .then((response) => setData2(response.data && response.data.data.length ? response.data.data[0] : {}))
    .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  return (
    <>
      <AppBarCustom position="relative" sx={{ minHeight: 10 }}>
        <Container sx={{ mb: 0, pb: 0 }}>
          <Toolbar sx={{ m: 0, p: 0 }}>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                display: { xs: "none", md: "flex" },
                m: 0,
              }}
            >
              <Typography sx={{ mr: 2 }}>
                <FontAwesomeIcon
                  icon={faMobileScreen}
                  style={{ color: "#ffc000" }}
                />{" "}
                <a
                  href={`tel:${data1?.telefone}`}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  {data1?.telefone}
                </a>
              </Typography>
              <Divider />
              <Typography>
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  style={{ color: "#ffc000", }}
                />{" "}
                {data1?.bairro}, {data1?.cidade} - {data1?.estado}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, m: 0 }}></Box>
            <Box sx={{ flexGrow: 1, m: 0 }}>
              <Tooltip title="Encontre-nos no Facebook">
                <Button
                  href={data2?.facebook}
                  target="_blank"
                  variant="contained"
                  size="small"
                  sx={{
                    p: 1,
                    mx: 1,
                    borderRadius: 50,
                    width: "auto",
                    minWidth: "auto",
                  }}
                >
                  <FacebookIcon fontSize="1em" />
                </Button>
              </Tooltip>
              <Tooltip title="Assista-nos no Youtube">
                <Button
                  href={data2?.youtube}
                  target="_blank"
                  variant="contained"
                  color="error"
                  size="small"
                  sx={{
                    p: 1,
                    mx: 1,
                    borderRadius: 50,
                    width: "auto",
                    minWidth: "auto",
                  }}
                >
                  <YouTubeIcon fontSize="1em" />
                </Button>
              </Tooltip>
              <Tooltip title="Siga-nos no TikTok">
                <Button
                  href={data2?.tiktok}
                  variant="contained"
                  size="small"
                  sx={{
                    p: 1,
                    mx: 1,
                    borderRadius: 50,
                    width: "auto",
                    minWidth: "auto",
                  }}
                >
                  <FontAwesomeIcon icon={faTiktok} />
                </Button>
              </Tooltip>
              <Tooltip title="Siga-nos no Instagram">
                <Button
                  href={data2?.instagram}
                  target="_blank"
                  style={{ backgroundColor: "#ff4880" }}
                  variant="contained"
                  size="small"
                  sx={{
                    p: 1,
                    mx: 1,
                    borderRadius: 50,
                    width: "auto",
                    minWidth: "auto",
                  }}
                >
                  <InstagramIcon fontSize="1em" />
                </Button>
              </Tooltip>
              <Tooltip title="Fale conosco no WhatsApp">
                <Button
                  href={`https://api.whatsapp.com/send?phone=${data2?.whatsapp?.replace('-', '')?.replace('(', '')?.replace(')', '')?.replace(' ', '')}&text=Ol%C3%A1!%20Tudo%20bem%3F%20Gostaria%20de%20agendar%20uma%20avalia%C3%A7%C3%A3o.%20`}
                  variant="contained"
                  size="small"
                  color="success"
                  target="_blank"
                  sx={{
                    p: 1,
                    mx: 1,
                    borderRadius: 50,
                    width: "auto",
                    minWidth: "auto",
                  }}
                >
                  <WhatsAppIcon fontSize="1em" />
                </Button>
              </Tooltip>
            </Box>
            {/* <IconButton aria-label="fingerprint" variant="contained" color="secondary">
            <Fingerprint />
          </IconButton>
          <IconButton aria-label="fingerprint" color="success">
            <Fingerprint />
          </IconButton> */}
          </Toolbar>
        </Container>
      </AppBarCustom>
    </>
  );
};

export default AddressHeaderComponent;
