import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Card, Grid } from "@mui/material";
import ContactFormComponent from "../ContactForm/ContactFormComponent";
import { apiUrl, authorizationBasicToken } from "../../config.js";

const MapsComponent = () => {
  const headers = {
    'Authorization': authorizationBasicToken,
    'Content-Type': 'application/json'
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    getDadosContato();
  }, []);

  const getDadosContato = async () => {
    axios.get(`${apiUrl}/dados-contato`, {
      headers: headers,
    })
    .then((response) => setData(response.data && response.data.data.length ? response.data.data[0] : {}))
    .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  return (
    <>
      <Grid container style={{ marginBottom: 100 }}>
        <Grid item md={4} sm={12} sx={{ pl: { sx: 0, md: 5, width: "100%" } }}>
          <Card
            sx={{
              pb: { sx: 0, md: 1 },
              m: 0,
            }}
          >
            <ContactFormComponent />
          </Card>
        </Grid>
        <Grid
          item
          md={8}
          sm={12}
          xs={12}
          sx={{
            px: { sx: 0, md: 4 },
            pr: { sx: 0, md: 5 },
            py: { sx: 6, md: 2 },
            width: "100%",
          }}
        >
          <iframe
            src={data?.url_google_maps}
            width="100%"
            height="100%"
            style={{ border: 0, height: "100%", borderRadius: 10 }}
          ></iframe>
        </Grid>
      </Grid>
    </>
  );
};

export default MapsComponent;
