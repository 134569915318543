import React from "react";
import BackgroundImage from "../../assets/lego-image.jpg";
import TitleSectionComponent from "../../components/TitleSectionComponent/TitleSectionComponent";
import MapsComponent from "../../components/MapsComponent/MapsComponent";
import InformationComponent from "../../components/InformationsComponent/InformationComponent";
import TopNavbar from "../../components/Navigation/TopNavbar";
import "./FaleConosco.css";

const FaleConosco = () => {
    return (
        <>
            <TopNavbar showAddresses />
            <TitleSectionComponent
                title="Fale Conosco"
                backgroundImage={BackgroundImage}
            />
            <InformationComponent />
            <MapsComponent />
        </>
    )
}


export default FaleConosco;
