import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Divider, Grid, Box, Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocation, faMessage } from "@fortawesome/free-solid-svg-icons";
import { apiUrl, authorizationBasicToken } from "../../config.js";

const InformationComponent = () => {
  const headers = {
    'Authorization': authorizationBasicToken,
    'Content-Type': 'application/json'
  };

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  useEffect(() => {
    getDadosContato();
    getRedesSociais();
  }, []);

  const getDadosContato = async () => {
    axios.get(`${apiUrl}/dados-contato`, {
      headers: headers,
    })
      .then((response) => setData1(response.data && response.data.data.length ? response.data.data[0] : {}))
      .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  const getRedesSociais = async () => {
    axios.get(`${apiUrl}/redes-sociais`, {
      headers: headers,
    })
    .then((response) => setData2(response.data && response.data.data.length ? response.data.data[0] : {}))
    .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50%",
  };

  const contentStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const h1Style = {
    fontFamily: "Fredoka One",
    color: "#271344",
    fontSize: "16px",
    marginBottom: "8px",
  };

  const pStyle = {
    fontFamily: "Prompt",
    color: "#777777",
    fontSize: "14px",
    marginBottom: "6px",
  };

  return (
    <Container style={containerStyle}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
          style={contentStyle}
        >
          <Grid item xs={12} sm={6} md={4}>
            <div>
              <FontAwesomeIcon
                style={{ color: "#FF4880" }}
                icon={faMapLocation}
              />
              <h1 style={h1Style}>Nosso Endereço</h1>
              <p style={pStyle}>{data1?.endereco}, {data1?.numero}</p>
              <p style={pStyle}>{data1?.bairro}, {data1?.cidade} - {data1?.estado}.</p>
            </div>
          </Grid>
          <Divider orientation="vertical" flexItem></Divider>
          <Grid item xs={12} sm={6} md={4}>
            <div>
              <FontAwesomeIcon style={{ color: "#1ab9ff" }} icon={faMessage} />
              <h1 style={h1Style}>Contato</h1>
              <p style={pStyle}>Tel.: {data1?.telefone}</p>
              <p style={pStyle}>WhatsApp: {data2?.whatsapp}</p>
              <p style={pStyle}>{data1?.email}</p>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default InformationComponent;
