import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TitleSectionComponent from "../components/TitleSectionComponent/TitleSectionComponent";
import BackgroundImage from "../assets/lego-image.jpg";
import TopNavbar from "../components/Navigation/TopNavbar";
import { apiUrl, authorizationBasicToken } from "../config.js";

const MetodologiaIntegrar = () => {
    const headers = {
        'Authorization': authorizationBasicToken,
        'Content-Type': 'application/json'
    };

    const [data, setData] = useState([]);

    useEffect(() => {
        getMetodologia();
    }, []);

    const getMetodologia = async () => {
        axios.get(`${apiUrl}/autismo/metodologia`, {
            headers: headers,
        })
        .then((response) => setData(
            response.data && response.data?.data.length ? response.data.data.sort((a, b) => (a.ordem > b.ordem ? 1 : -1)) : [])
        )
        .catch((error) => console.error('Erro ao buscar dados:', error));
    };

    const containerStyle = {
        display: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        paddingLeft: '40px',
        paddingRight: '40px',
        textAlign: 'justify',
    };

    const h1Style = {
        fontFamily: 'Fredoka One',
        color: '#271344',
        fontSize: '40px'
    };

    const pStyle = {
        fontFamily: prompt,
        color: '#777777'
    };

    return (
        <>
            <TopNavbar showAddresses />
            <TitleSectionComponent
                title="Centro Integrar"
                backgroundImage={BackgroundImage} />
            <div style={containerStyle}>
                {data?.map((item) => (
                    <div key={item.id}>
                        <h1 style={h1Style}>
                            {item.titulo}
                        </h1>
                        <p style={pStyle}>
                            {item.descricao}
                        </p>
                    </div>
                ))}
            </div>
        </>
    )
}

export default MetodologiaIntegrar;
