import React, { useState, useEffect } from "react";
import axios from 'axios';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './SlideComponent.css';
import { Box, Grid, Button, Typography, styled } from '@mui/material';
import { apiUrl, authorizationBasicToken } from "../../config.js";

const ButtonStyled = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  marginInline: 2,
  backgroundColor: '#ff4880',
  color: '#fff',
  fontWeight: 500,
  fontSize: 15,
  paddingBlock: 10,
  paddingInline: 20,
  fontFamily: [
    'Prompt',
  ].join(','),
  '&:hover': {
    backgroundColor: '#271344',
    color: '#fff',
  },
  '&:active': {
    backgroundColor: '#271344',
    color: '#fff',
  }
});

const SlideItemComponent = (props) => {
  const { image, beforeTitle, title, description, actions } = props
  return (
    <>
      <div>
        <div className="image-slide">
          <Box className="mask" sx={{ p: 5, }}>
            <Grid container justifyContent="flex-start" alignItems="center" sx={{ height: '100%' }}>
              <Grid item xs={12}>
                <Typography
                  component="div"
                  fontFamily={'Schoolbell'}
                  sx={{
                    fontSize: {
                      md: 25,
                      xs: 18
                    },
                    textTransform: 'capitalize',
                    // letterSpacing: 1.5,
                    color: '#ff4880',
                    fontWeight: 600,
                    letterSpacing: 3.4,
                    maxWidth: '40%'
                  }}
                >
                  {beforeTitle}
                </Typography>
                <Typography
                  fontFamily={'Fredoka One'}
                  // textAlign={'center'}
                  component="div"
                  sx={{
                    fontSize: {
                      md: 50,
                      xs: 20
                    },
                    color: '#271344',
                    fontWeight: 400,
                    maxWidth: '40%'
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  fontFamily={'Prompt'}
                  sx={{
                    fontSize: {
                      md: 17,
                      xs: 10
                    },
                    color: '#54595f',
                    fontWeight: 400,
                    maxWidth: '40%'
                  }}
                >
                  {description}
                </Typography>
                <Box
                  sx={{
                    mt: 4
                  }}
                >
                  {actions}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <img
            src={image}
            alt={title}
            className="slide-image"
          />
        </div>
      </div >
    </>
  )
}

const SlideComponent = () => {

  const headers = {
    'Authorization': authorizationBasicToken,
    'Content-Type': 'application/json'
  };

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  useEffect(() => {
    getHomeSlide();
    getRedesSociais();
  }, []);

  const getHomeSlide = async () => {
    axios.get(`${apiUrl}/home-slide`, {
      headers: headers,
    })
    .then((response) => setData1(
      response.data && response.data?.data.length ? response.data.data.sort((a, b) => (a.ordem > b.ordem ? 1 : -1)) : [])
    )
    .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  const getRedesSociais = async () => {
    axios.get(`${apiUrl}/redes-sociais`, {
      headers: headers,
    })
    .then((response) => setData2(response.data && response.data.data.length ? response.data.data[0] : {}))
    .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  const settings = {
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    autoplay: true,
    nextArrow: <></>,
    prevArrow: <></>,
    customPaging: function (i) {
      return (
        <Button>
          {i + 1}
        </Button>
      );
    },
    dots: false,
  };

  return (
    <Box>
      <Slider {...settings}>
        {data1?.map((item) => (
          <SlideItemComponent
            key={item.id}
            image={apiUrl + '/' + item.imagem}
            beforeTitle={item?.titulo}
            title={item?.subtitulo}
            description={item?.descricao}
            actions={
              <ButtonStyled href={item?.link_botao} target="_blank" rel="noopener noreferrer">
                {item?.texto_botao}
              </ButtonStyled>
            }
          />
        ))}
        {/* <SlideItemComponent
          image={image1}
          beforeTitle={firstSlide?.titulo}
          title={firstSlide?.subtitulo}
          description={firstSlide?.descricao}
          actions={
            <ButtonStyled href={`https://api.whatsapp.com/send?phone=${data2?.whatsapp?.replace('-', '')?.replace('(', '')?.replace(')', '')?.replace(' ', '')}&text=Ol%C3%A1!%20Tudo%20bem%3F%20Gostaria%20de%20agendar%20uma%20avalia%C3%A7%C3%A3o.%20`} target="_blank" rel="noopener noreferrer">
              Agende uma avaliação
            </ButtonStyled>
          }
        /> */}
        {/* <SlideItemComponent
          image={image2}
          beforeTitle={secondSlide?.titulo}
          title={secondSlide?.subtitulo}
          description={secondSlide?.descricao}
          actions={
            <ButtonStyled href={`https://api.whatsapp.com/send?phone=${data2?.whatsapp?.replace('-', '')?.replace('(', '')?.replace(')', '')?.replace(' ', '')}&text=Ol%C3%A1!%20Tudo%20bem%3F%20Gostaria%20de%20agendar%20uma%20avalia%C3%A7%C3%A3o.%20`} target="_blank" rel="noopener noreferrer">
              Agende uma avaliação
            </ButtonStyled>
          }
        /> */}
      </Slider>
    </Box>
  );
};

export default SlideComponent;
