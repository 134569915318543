import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TitleSectionComponent from "../../components/TitleSectionComponent/TitleSectionComponent";
import TitlePageComponent from "../../components/Titles/TitlePageComponent";
import BackgroundImage from "../../assets/lego-image.jpg";
import ChildImage from "../../assets/child-man.png";
import PaintImage from "../../assets/color1.png";
import BookImage from "../../assets/icon-fcb-10.png";
import CrampImage from "../../assets/tesoura.png";
import "./Atividades.css";
import TopNavbar from "../../components/Navigation/TopNavbar";
import { apiUrl, authorizationBasicToken } from "../../config.js";

const Atividades = () => {
  const headers = {
    'Authorization': authorizationBasicToken,
    'Content-Type': 'application/json'
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    getAtividades();
  }, []);

  const getAtividades = async () => {
    axios.get(`${apiUrl}/atividade`, {
      headers: headers,
    })
    .then((response) => setData(
      response.data && response.data?.data.length ? response.data.data.sort((a, b) => (a.ordem > b.ordem ? 1 : -1)) : [])
    )
    .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  const atividade1 = data?.length && data?.length > 0 ? data[0] : {};
  const atividade2 = data?.length && data?.length > 1 ? data[1] : {};
  const atividade3 = data?.length && data?.length > 2 ? data[2] : {};
  const atividade4 = data?.length && data?.length > 3 ? data[3] : {};

  const pStyle = {
    fontFamily:  'Fredoka One',
    fontSize: '24px',
    color: '#271344',
  }

  return (
    <>
      <TopNavbar showAddresses />
      <TitleSectionComponent
        title="Atividades"
        backgroundImage={BackgroundImage}
      />

      <div className="content-container">
        <TitlePageComponent
          beforeTitle={"Faça o seu"}
          title={"Download"}
          subtitle={"O Centro Integrar desenvolve e aplica dentro dos Programas de Intervenção, atividades e brincadeiras para estimular e ajudar no desenvolvimento e no processo de aprendizagem do seu filho. Nesta página você poderá acessar alguns dos nossos materiais e aplicar em sua casa um pouco dos métodos utilizados pelos nossos terapeutas e pela família dos nossos pequenos!"}
        />

        <div className="icon-container">
          <div className="icon-left">
            <div className="icon">
              <div className="circle-background-book">
                <a target="_blank" href={atividade1.link_arquivo}>
                <img src={apiUrl + '/' + atividade1.icone} alt="Livro" style={{ width: '35px', height: 'auto' }} />
              </a>
              </div>
              <p style={pStyle}>{atividade1.titulo}</p>
            </div>

            <a target="_blank" style={{ textDecoration: 'none' }} href={atividade2.link_arquivo}>
            <div className="icon">
              <div className="circle-background-cramp">
                <img src={apiUrl + '/' + atividade2.icone} className="icon-image" alt="Tesoura" style={{ width: '35px', height: 'auto' }} />
              </div>
                <p style={pStyle}>{atividade2.titulo}</p>
            </div>
            </a>
          </div>

          <img src={ChildImage} alt="Criança" />

          <div className="icon-right">
            <div className="icon">
              <div className="circle-background-paint">
                <a target="_blank" href={atividade3.link_arquivo}>
                <img src={apiUrl + '/' + atividade3.icone} className="icon-image" alt="Pincel" style={{ width: '35px', height: 'auto' }} />
              </a>
              </div>
              <p style={pStyle}>{atividade3.titulo}</p>
            </div>

            <div className="icon">
              <div className="circle-background-paint2">
                <a target="_blank" href={atividade4.link_arquivo}>
                  <img src={apiUrl + '/' + atividade4.icone} className="icon-image" alt="Pincel" style={{ width: '35px', height: 'auto' }} />
              </a>
              </div>
              <p style={pStyle}>{atividade4.titulo}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Atividades;
