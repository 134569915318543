import { Box, Typography } from "@mui/material"
import BeeImage from '../../assets/image-bee.png';

const TitlePageComponent = (props) => {

    const { title, beforeTitle, subtitle } = props

    return (
        <>
            <Typography
                component="div"
                textAlign={'center'}
                fontFamily={'Schoolbell'}
                sx={{
                    fontSize: 17,
                    color: '#ff4880',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    letterSpacing: 3.4
                }}
            >
                {beforeTitle}
            </Typography>
            <Typography
                fontFamily={'Fredoka One'}
                textAlign={'center'}
                component="div"
                sx={{
                    fontSize: 40,
                    color: '#271344',
                    fontWeight: 400
                }}
            >
                {title}
            </Typography>
            <Typography
                component="div"
                textAlign={'center'}
                fontFamily={'Prompt'}
                sx={{
                    fontSize: 14,
                    color: '#777777',
                    fontWeight: 400,
                    letterSpacing: 1.4,
                    textAlign: 'center'
                }}
            >
                {subtitle}
            </Typography >
            <Box sx={{ flex: 1, textAlign: 'center', alignItems: 'center' }}>
                <img className="animated-image" src={BeeImage} />
            </Box>
        </>
    )
}

export default TitlePageComponent