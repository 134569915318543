import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import TitlePageComponent from '../Titles/TitlePageComponent';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { apiUrl, authorizationBasicToken } from "../../config.js";

import './MidiaComponent.css';

import image1 from '../../assets/midia/midia1.png';
import image2 from '../../assets/midia/midia2.png';
import image3 from '../../assets/midia/midia3.png';
import image4 from '../../assets/midia/midia4.png';
import image5 from '../../assets/midia/midia5.png';
import { Box, Button, Card, CardContent, CardMedia, Container, Typography } from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const images = [
  {
    id: 1,
    src: image1,
    caption: 'Diagnóstico TEA',
    isLink: true,
    url: 'https://www.youtube.com/watch?v=SS_unXvro8M'
  },
  {
    id: 2,
    src: image2,
    caption: 'Capacitação para reconhecimento de sinais de autismo',
  },
  {
    id: 3,
    src: image3,
    caption: 'Projeto Amigos do Autista',
  },
  {
    id: 4,
    src: image4,
    caption: 'Encontro com Presidenta e Vice-Presidente do Coren-BA',
  },
  {
    id: 5,
    src: image5,
    caption: 'Diário Oficial',
  },
];

const MidiaComponent = () => {
  const headers = {
    'Authorization': authorizationBasicToken,
    'Content-Type': 'application/json'
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    getMidias();
  }, []);

  const getMidias = async () => {
    axios.get(`${apiUrl}/home-na-midia`, {
      headers: headers,
    })
    .then((response) => setData(      
      response.data && response.data?.data.length ? response.data.data.sort((a, b) => (a.ordem > b.ordem ? 1 : -1)) : [])
    )
    .catch((error) => console.error('Erro ao buscar dados:', error));
  };

  const [expandedImage, setExpandedImage] = useState(null);

  const handleImageClick = (image) => {
    setExpandedImage(apiUrl + '/' + image.imagem);
  };

  const handleCloseExpandedImage = () => {
    setExpandedImage(null);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: false,
    nextArrow:
      <Box
        sx={{
          display: {
            md: 'block !important',
            sm: 'none !important',
            xs: 'none !important'
          }
        }}
      >
        <Button variant="outlined" sx={{ borderRadius: 15, px: 1, py: 1 }}>
          <NavigateNextIcon />
        </Button>
      </Box>
    ,
    prevArrow:
      <Box
        sx={{
          display: {
            md: 'block !important',
            sm: 'none !important',
            xs: 'none !important'
          }
        }}
      >
        <Button variant="outlined" sx={{ borderRadius: 15, px: 1, py: 1, ml: -5.5 }}>
          <NavigateBeforeIcon />
        </Button>
      </Box>
    ,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
    ]
  };

  return (
    <div>
      <TitlePageComponent beforeTitle="Na mídia com o" title="Centro Integrar" />
      <Container maxWidth={'md'} sx={{ mb: 8, borderRadius: 15 }}>
        <Slider style={{ marginTop: '60px' }} {...settings}>
          {
            data?.map((image) => {

              return (
                <Fragment key={image.id}>
                  <Card sx={{ mx: 1, mb: 1, borderRadius: 5 }}>
                    <CardMedia
                      sx={{ height: 300, cursor: 'pointer' }}
                      image={apiUrl + '/' + image.imagem}
                      title="green iguana"
                      onClick={() => {
                        if (image.link_video) {
                          window.open(image.link_video, '_blank')
                        } else {
                          handleImageClick(image)
                        }
                      }}
                    />
                    <CardContent sx={{ backgroundColor: '#f0f0f0', textAlign: 'center' }}>
                      <Typography gutterBottom variant="h5" component="div" fontSize={'16px'} fontFamily={'Fredoka One'}>
                        {image.titulo}
                      </Typography>
                    </CardContent>
                  </Card>
                </Fragment>
              )
            })
          }
          {/* {images.map((image) => (
            <div key={image.id} style={{ marginBottom: '10px', height: 'auto', borderRadius: '10px', overflow: 'hidden', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
              <div
                style={{
                  cursor: 'pointer',
                  overflow: 'hidden',
                  borderRadius: '10px 10px 0 0',
                }}
                onClick={() => handleImageClick(image)}
              >
                <img src={image.src} alt={`Imagem ${image.id}`} style={{ width: '100%', height: 'auto' }} />
              </div>
              <div style={{ padding: '10px', backgroundColor: '#f0f0f0', borderRadius: '0 0 10px 10px' }}>
                <p style={{ fontFamily: 'Fredoka One', textAlign: 'center', fontSize: '16px' }}>{image.caption}</p>
              </div>
            </div>
          ))} */}
        </Slider>
      </Container>

      {expandedImage && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1101
          }}
          onClick={handleCloseExpandedImage}
        >
          <div style={{ maxWidth: '90%', maxHeight: '90%' }}>
            <img src={expandedImage} alt="Imagem expandida" style={{ width: '100%', height: 'auto' }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MidiaComponent;
